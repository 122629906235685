/* Home.css */

/* Overall container styling */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: rgb(255, 255, 255);
  color: #333;
  text-align: center;
}

/* Content styling */
.home-content {
  max-width: 800px;
}

.home-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff5722;
}

.home-intro {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 30px;
}

/* Button styling */
.home-button {
  background-color: #ff5722;
  color: #fff;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.home-button:hover {
  background-color: #e64a19;
}

/* Image link styling */
.image-link {
  text-decoration: none;
  color: inherit;
}

/* Image container styling */
.image-container {
  margin: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Image wrapper styling */
.image-wrapper {
  width: 100%;
  max-width: 300px;
  height: auto;
}

/* Button container styling */
.image-button-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Centered image container styling */
.centered-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.centered-image {
  max-width: 100%;
  max-height: 100%;
}

/* Image styling */
.home-image-img {
  max-width: 300px;
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1s ease-in-out;
}

/* Image caption styling */
.image-caption {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #777;
}

/* Keyframe animation for fading in images */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media (max-width: 768px) {
  .home-content {
    margin-top: 1600px;
  }
} */
