/* About Us container styling */
.about-us-container {
    text-align: center;
    padding: 50px;
  }
  
  /* About Us heading styling */
  .about-us-heading {
    font-size: 36px;
    margin-bottom: 20px;
    color: #874d3d; /* Your brand color */
  }
  
  /* About Us content container */
  .about-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* About Us image styling */
  .about-us-image {
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 50%;
    max-height: 70vh;
  }
  
  /* About Us image img styling */
  .about-us-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* About Us text styling */
  .about-us-text {
    max-width: 800px;
    text-align: left;
    font-size: 18px;
    line-height: 1.5;
    color: #555; /* Text color */
  }
  
  /* About Us text paragraph styling */
  .about-us-text p {
    margin-bottom: 20px;
  }
  
  /* About Us text link styling */
  .about-us-text a {
    color: #874d3d; /* Link color */
    text-decoration: none;
    font-weight: bold;
  }
  
  /* About Us text link hover effect */
  .about-us-text a:hover {
    text-decoration: underline;
  }
  