/* Header container styling */
.header-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), #874d3d; /* Coffee-colored gradient with transparency */
    color: #fff;
    padding: 10px 20px;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow-x: hidden;
  }
  
  /* Logo link styling */
  .logo-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: auto; /* Push the logo to the left corner */
    margin-left: 20px; /* Add some margin for spacing */
    animation: logoBounce 1s infinite alternate; /* Logo animation */
  }
  
  /* Logo image styling */
  .logo {
    width: 40px; /* Adjust the width as needed */
    height: auto;
    transition: transform 0.3s ease; /* Logo hover effect */
    margin-left: 20px;
  }
  
  /* Logo hover effect */
  .logo:hover {
    transform: scale(1.1); /* Scale up the logo on hover */
  }
  
  /* Hamburger button styling */
  .hamburger-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    margin-right: 10px; /* Add margin between hamburger button and links */
  }
  
  /* Close button styling */
  .close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    margin-left: auto; /* Push the close button to the right corner */
    margin-right: 10px; /* Add margin between close button and links */
  }
  
  /* Mobile navigation overlay (for closing menu when clicked outside) */
  .mobile-nav-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  
  /* Mobile navigation styling */
  .mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 70%; /* Adjust as needed for menu width */
    height: 100%;
    background-color: #333;
    color: #fff;
    z-index: 100;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  /* Mobile navigation list styling */
  .mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1; /* Allow the list to grow and center its items */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
  }
  
  /* Mobile navigation item styling */
  .mobile-nav-item {
    padding: 10px;
    text-align: center; /* Center text within each item */
  }
  
  /* Mobile navigation link styling */
  .mobile-nav-link {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  /* Open the mobile menu when the hamburger button is clicked */
  .hamburger-menu-container.open .mobile-nav {
    transform: translateX(0);
  }
  
  .hamburger-menu-container.open .mobile-nav-overlay {
    display: block;
  }
  
  /* Regular navigation list styling */
  .nav-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  /* Regular navigation item styling */
  .nav-item {
    margin: 0 10px; /* Add margin between menu items */
  }
  
  /* Regular navigation link styling */
  .nav-link {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: color 0.3s ease;
    padding: 15px; /* Increase padding for the link highlight */
  }
  
  /* Highlight the active link (for regular menu) */
  .active {
    background-color: #ff5733;
    border-radius: 5px;
    padding: 15px; /* Increase padding for the active link */
  }
  /* Add an underline effect on hover (for regular menu) */
  .nav-link:hover {
    text-decoration: underline;
  }
  