/* Hamburger menu container styling */
.hamburger-menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

/* Hamburger button styling */
.hamburger-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

/* Mobile navigation overlay (for closing menu when clicked outside) */
.mobile-nav-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* Mobile navigation styling */
.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%; /* Adjust as needed for menu width */
  height: 100%;
  background-color: #333;
  color: #fff;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

/* Mobile navigation list styling */
.mobile-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%; /* Adjust as needed for menu width */
  height: 100%;
  background-color: #333;
  color: #fff;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

/* Close button styling (white when menu is open) */
.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff; /* Default color when menu is closed */
  align-self: flex-start; /* Align to the top left corner */
  padding: 10px;
}

/* White color for close button when menu is open */
.hamburger-menu-container.open .close-button {
  color: #fff;
}
/* Mobile navigation item styling */
.mobile-nav-item {
  padding: 10px;
  text-align: right;
}

/* Open the mobile menu when the hamburger button is clicked */
.hamburger-menu-container.open .mobile-nav {
  transform: translateX(0);
}

.hamburger-menu-container.open .mobile-nav-overlay {
  display: block;
}
