/* Contacts container styling */
.contact-container {
    text-align: center;
    padding: 20px;
    margin-top: 50px;
  }
  
  /* Contact heading styling */
  .contact-heading {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  /* Contact introduction text styling */
  .contact-intro {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* Contact form container styling */
  .contact-form-container {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the form horizontally */
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Label styling */
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Input field styling */
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select { /* Add select here for dropdown styling */
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Submit button styling */
  .submit-button {
    background-color: #874d3d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #6b3c2a;
  }
  