/* Gallery container */
.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 300px; /* Adjust this value as needed to match your header's height */
  }
  
  /* Thumbnail grid */
  .thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    max-width: 800px;
    margin: 20px;
  }
  
  /* Responsive layout for tablets */
  @media (max-width: 768px) {
    .thumbnail-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
  
  /* Responsive layout for mobile */
  @media (max-width: 480px) {
    .thumbnail-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  /* Thumbnail */
  .thumbnail {
    cursor: pointer;
    position: relative;
  }
  
  /* Thumbnail image */
  .thumbnail img {
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: opacity 0.3s; /* Add opacity transition for a smoother effect */
  
    /* Reset the transform scale for mobile */
    @media (max-width: 480px) {
      transform: none;
    }
  }
  
  /* Mobile-friendly hover effect */
  .thumbnail:hover img {
    opacity: 0.8; /* Reduce opacity on hover for a subtle effect */
  }
  
  /* Image overlay */
  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Image popup */
  .image-popup {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 80%;
  }
  
  /* Image inside the popup */
  .image-popup img {
    max-width: 100%;
    max-height: 70vh;
    display: block;
    margin: 0 auto;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #ff5733;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Close button hover effect */
  .close-button:hover {
    background: #e5472a;
  }
  