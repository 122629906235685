/* Courses container styling */
.courses-container {
    text-align: center;
    padding: 20px;
    margin-top: 50px;
  }
  
  /* Courses heading styling */
  .courses-heading {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  /* Course list styling */
  .course-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center-align course cards */
    gap: 20px; /* Add gap between course cards */
  }
  
  /* Course card container styling */
  .course-card-container {
    width: calc(33.33% - 20px); /* Adjust the width of each card */
    max-width: 300px; /* Set a maximum width for each card */
  }
  
  /* Course card styling */
  .course-card {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Course card title styling */
  .course-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Course card description styling */
  .course-card p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  /* Button container for equal-sized buttons */
  .button-container {
    display: flex;
    flex-direction: column; /* Display buttons in a column */
    align-items: center; /* Center-align buttons */
  }
  
  /* More Info button styling */
  .course-card button {
    background-color: #874d3d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    text-align: center; /* Center-align text */
  }
  
  .course-card button:hover {
    background-color: #6b3c2a;
  }
  
  /* Enquiry button styling */
  .course-card .enquiry-button {
    background-color: #ff5733;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin-top: 10px;
    text-align: center; /* Center-align text */
  }
  
  .course-card .enquiry-button:hover {
    background-color: #e5472a;
  }
  
  /* Popup container styling */
  .popup {
    background-color: rgba(0, 0, 0, 0); /* Background overlay with 80% opacity */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 80%;
    text-align: justify;
  }
  
  .popup-content h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 20px;
  }
  
  .popup-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .popup-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .popup-content li {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  
  .popup-content p {
    font-size: 18px;
    margin-top: 20px;
    line-height: 1.6;
  }
  
 
  
  .popup-content button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  
  /* Popup content styling */
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button styling */
  .popup-content button {
    background-color: #ff5733;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    align-items: center;
  }
  
  .popup-content button:hover {
    background-color: #e5472a;
  }
  