/* Footer container styling */
.footer-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), #874d3d; /* Coffee-colored gradient with transparency */
  color: #fff;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
    width: 100%; /* Make the footer full width */
    margin-top: 50px;
  }
  
  /* Footer column styling */
  .footer-column {
    flex: 1; /* Equal width for columns */
    padding: 0 20px; /* Add spacing between columns */
    text-align: center;
  }
  
  /* Social links container */
  .social-links {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  /* Social link styling */
  .social-link {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  /* Social link hover effect */
  .social-link:hover {
    color: #ff5733;
  }
  
  /* Footer text styling */
  .footer-text {
    font-size: 14px;
  }
  
  /* Footer navigation styling */
  .footer-nav {
    text-align: center;
  }
  
  /* Footer navigation list styling */
  .footer-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Footer navigation item styling */
  .footer-nav-item {
    margin: 10px 0; /* Add vertical spacing between navigation items */
  }
  
  /* Footer navigation link styling */
  .footer-nav-link {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  /* Footer navigation link hover effect */
  .footer-nav-link:hover {
    text-decoration: underline;
  }
  